import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import process1 from '../../assets/images/process/process1.png'
import process2 from '../../assets/images/process/process2.png'
import process3 from '../../assets/images/process/process3.png'
import process4 from '../../assets/images/process/process4.png'
import process5 from '../../assets/images/process/process5.png'
import process6 from '../../assets/images/process/process6.png'
import shape from '../../assets/images/shape/circle-shape1.png'

const HowItWork = () => {
    return (
        <section className="process-area pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="about" /> 
                        How It's Work
                    </span>
                    <h2>The Support Process</h2>
                    <p>Well it starts with your enquiry, we will listen to what you have to say and will learn about the issue. The we arrange a time with which is at your earliest convieince to remote on to your Mac, you can be on the call at the same time and we can discuss what we are doing and why, you will see all the work being carried out on your Mac. Once we have completed the work we send you an invoice via email with a link for payment, and that is it in a nutshell</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="single-process-box">
                            <div className="number">1</div>
                            <div className="image">
                                <img src={process1} alt="about" />
                            </div>
                            <h3>Discuss the issue</h3>
                            <p>When did it happen, what is the issue, what is affected.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-process-box">
                            <div className="number">2</div>
                            <div className="image">
                                <img src={process2} alt="about" />
                            </div>
                            <h3>Remote, Oniste or Mail in</h3>
                            <p>We determine the best next step, 9 times out of 10 issues can be resolved remotely without a visit.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-process-box">
                            <div className="number">3</div>
                            <div className="image">
                                <img src={process3} alt="about" />
                            </div>
                            <h3>We go to Work</h3>
                            <p>We commence and fix your Mac in the quickest possible time.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-process-box ">
                            <div className="number">4</div>
                            <div className="image">
                                <img src={process4} alt="about" />
                            </div>
                            <h3>Reporting to You</h3>
                            <p>We like to explain what we found with our insights.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-process-box">
                            <div className="number">5</div>
                            <div className="image">
                                <img src={process5} alt="about" />
                            </div>
                            <h3>Performance Check</h3>
                            <p>If you request and at no extra charge we are happy to provide a performance check on your Mac</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-process-box">
                            <div className="number">6</div>
                            <div className="image">
                                <img src={process6} alt="about" />
                            </div>
                            <h3>We Complete the Session</h3>
                            <p>We close the remote session so that the Mac is not open to any more access, have a chat and say goodbye.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape} alt="about" />
            </div>
        </section>
    )
}

export default HowItWork