import React from 'react'
import { Link } from 'gatsby'
import aboutImage from '../../assets/images/about/about-img5.png'
import starIcon from '../../assets/images/star-icon.png'
import icon4 from '../../assets/images/icons/icon4.png'
import icon5 from '../../assets/images/icons/icon5.png'
import icon6 from '../../assets/images/icons/icon6.png'
import icon7 from '../../assets/images/icons/icon7.png'
import shape1 from '../../assets/images/shape/circle-shape1.png'
 
const AboutUsContent = () => {
    return (
        <section className="about-area ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                            <img src={aboutImage} alt="banner" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" /> 
                                    About Us
                                </span>
                                <h2>Tech Expertise, Simply Delivered</h2>
                                <p></p>
                                
                                <ul className="features-list">
                                    <li>
                                        <img src={icon4} alt="banner" />
                                        <h3>22 Years</h3>
                                        <p>Mac Engineer</p>
                                    </li>
                                    <li>
                                        <img src={icon5} alt="banner" />
                                        <h3>2</h3>
                                        <p>Team members</p>
                                    </li>
                                    <li>
                                        <img src={icon6} alt="banner" />
                                        <h3>100%</h3>
                                        <p>Satisfaction rate</p>
                                    </li>
                                    <li>
                                        <img src={icon7} alt="banner" />
                                        <h3>80%</h3>
                                        <p>Apple</p>
                                    </li>
                                </ul>
                                <p>Our business is based near Lewes in East Sussex.  Delivering Service with Passion, Ego-Free..</p>
                                
                                <Link to="/about-us" className="default-btn">
                                    <i className="flaticon-right"></i>More About Us<span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>

            <div className="container">
                <div className="about-inner-area">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Our History</h3>
                                <p>Global Mac & PC support, Apple-certified</p>
                                
                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Mac Support</li>
                                    <li><i className="flaticon-tick"></i> Mac & PC Integrations</li>
                                    <li><i className="flaticon-tick"></i> Networking</li>
                                    <li><i className="flaticon-tick"></i> Mac Device Management</li>
                                </ul>
                            </div>
                        </div>
    
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Our Mission</h3>
                                <p>"Reimagining Mac & PC support with our customers at the core, ensuring a positive experience</p>
                                
                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Responding Instantly</li>
                                    <li><i className="flaticon-tick"></i> Delivering More Than Expected</li>
                                    <li><i className="flaticon-tick"></i> Improving our internal processes</li>
                                    <li><i className="flaticon-tick"></i> Achieve High Levels of Trust</li>
                                </ul>
                            </div>
                        </div>
    
                        <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                            <div className="about-text">
                                <h3>Who we are</h3>
                                <p>A boutique firm with a vast vision, dedicated to supporting all Mac and PC users.</p>
                                
                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Authenticity Over Ego</li>
                                    <li><i className="flaticon-tick"></i> We've got your back</li>
                                    <li><i className="flaticon-tick"></i> We turn heads</li>
                                    <li><i className="flaticon-tick"></i> It's about you, not us!</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>
        </section>
    )
}

export default AboutUsContent;