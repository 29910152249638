import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import howItWork from '../../assets/images/how-its-work.png'

const WhyChooseUs = () => {
    return (
        <section className="how-its-work-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-content">
                            <span className="sub-title">
                                <img src={starIcon} alt="banner" /> 
                                People Love Us
                            </span>
                            <h2>Why Choose Us?</h2>
                            <p>Well this isn't easy to say, I guess we want to give you the confidence to know that the Mac can sometimes go a little pear shaped, we are hoping there is no stress with the situation and look forward to getting you back to work.  You can choose us and we wont let you down.</p>
                            <div className="inner-box">
                                <div className="single-item">
                                    <div className="count-box">
                                        1
                                    </div>
                                    <h3>Apple Certified</h3>
                                    <p>We have had a silly amount of days weeks in Apple training, so knowledge is abundant.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        2
                                    </div>
                                    <h3>A New Style Service</h3>
                                    <p>We hope we can see the back of driving to fix Macs, remote support is where it is at.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        3
                                    </div>
                                    <h3>+ Technical Services</h3>
                                    <p>Whilst fixing your Mac we can talk about Cloud based Service or the next big thing.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-image">
                            <img src={howItWork} alt="banner" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyChooseUs